
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import _ from 'lodash'

@Component({
  components: { CopyClipboardComponent }
})
export default class OverviewCombobox extends Vue {
  @Prop({ required: true }) externalIds: string[]
  @Prop({ required: true }) isDisabled: boolean
  @Prop({ required: true }) label: string
  @Prop({ required: true }) errorMessages: string[]

  loadingSearchOverview = false
  searchIo = ''
  itemsOverviewCombobox: TextValue[] = []

  emitSyncExternalIds (value: string[]) {
    this.emitSync(value)
    this.searchIo = ''
  }

  removeFromExternalIds (item: string) {
    console.warn('removeFromExternalIds', item)
    const externalIds = _.cloneDeep(this.externalIds)
    _.remove(externalIds, (io) => io === item)
    this.emitSync(externalIds)
  }

  emitSync (value: string[]) {
    this.$emit(`update:externalIds`, value)
  }

  async searchOverview (search: string) {
    if (!search) {
      this.loadingSearchOverview = false
      return
    }
    this.loadingSearchOverview = true
    this.itemsOverviewCombobox = []
    const params = {
      subDsp: this.$route.params.dsp,
      search: search
    }
    const response = await this.$apiCaller.getOverviewsWithSearch(params, true)

    if (this.$apiCaller.isResponseError(response)) {
      console.warn('Error when calling api')
      this.$store.commit('setErrorMessageWithResponse', response)
    } else if (this.$apiCaller.isCancelError(response)) {
      console.warn('Request canceled')
    } else {
      if (response.data && response.data.length) {
        response.data.forEach((overview) => {
          this.itemsOverviewCombobox.push({
            value: overview.external_id,
            text: `${overview.external_id} - ${overview.name}`
          })
        })
      } else {
        this.itemsOverviewCombobox = []
      }
      this.loadingSearchOverview = false
    }
  }

  @Watch('searchIo')
  onSearchChange = _.debounce(async (search: string) => {
    await this.searchOverview(search)
  }, 600)
}
