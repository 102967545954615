import { render, staticRenderFns } from "./OverviewCombobox.vue?vue&type=template&id=830b0a26&scoped=true"
import script from "./OverviewCombobox.vue?vue&type=script&lang=ts"
export * from "./OverviewCombobox.vue?vue&type=script&lang=ts"
import style0 from "./OverviewCombobox.vue?vue&type=style&index=0&id=830b0a26&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830b0a26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VChip,VCombobox,VIcon})
