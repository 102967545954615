

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import * as rules from '../../../../../src/rules/rules'

@Component({})
export default class DatePicker extends Vue {
  @Prop()
  label: string
  @Prop()
  model: string
  @Prop()
  required: boolean
  @Prop()
  disabled: boolean
  @Prop()
  max: string
  @Prop()
  min: string
  @Prop({ default: (): string[] => [] })
  errorMessages: string[]

  @Prop({ default: false })
  clearable: boolean

  rules = rules
  dateMenu: boolean = false

  errorMessage (item: any) {
    let error: string[] = []
    if (!this.required) {
      return error
    }
    let ruleCheck: string | boolean = rules.required(item)
    let toReturn = ruleCheck !== true ? 'Field is required' : ''
    if (toReturn !== '') {
      error.push(toReturn)
    }
    return error
  }

  allErrorMessage () {
    return [...this.errorMessage(this.model), ...this.errorMessages]
  }
}

